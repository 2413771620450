var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "800" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "modalCard" },
        [
          _c(
            "v-toolbar",
            { staticClass: "main", attrs: { flat: "", dark: "" } },
            [_c("v-toolbar-title", [_vm._v("Reset Suggested Orders")])],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-col",
                [
                  _c("v-row", { staticClass: "my-1" }),
                  _c("v-row", { staticClass: "align-center" }, [
                    _c("span", { staticClass: "bold-uppercase warning-text" }, [
                      _vm._v("Warning:"),
                    ]),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "align-center" },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          id: "checkbox",
                          label:
                            "I understand that resetting the order grid changes all items back to their original suggested order and clears any user's entered changes.",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.localChecked,
                          callback: function ($$v) {
                            _vm.localChecked = $$v
                          },
                          expression: "localChecked",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$emit("update:value", false)
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3 amber darken-3 white--text",
                          attrs: { disabled: !_vm.localChecked },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("reset")
                            },
                          },
                        },
                        [_vm._v(" Reset Order Grid ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }