<template>
  <v-dialog v-model="value" persistent width="800">
    <v-card class="modalCard">
      <v-toolbar flat dark class="main">
        <v-toolbar-title>Reset Suggested Orders</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- Single Column Wrapping All Rows -->
        <v-col>
          <!-- Spacing for Warning using empty row -->
          <v-row class="my-1"></v-row>

          <!-- Spacing for Warning -->
          <v-row class="align-center">
            <span class="bold-uppercase warning-text">Warning:</span>
          </v-row>

          <!-- Checkbox Section -->
          <v-row class="align-center">
            <v-checkbox
              id="checkbox"
              v-model="localChecked"
              label="I understand that resetting the order grid changes all items back to their original suggested order and clears any user's entered changes."
              hide-details
            ></v-checkbox>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn @click="$emit('update:value', false)">Cancel</v-btn>
            <v-btn
              class="ml-3 amber darken-3 white--text"
              :disabled="!localChecked"
              @click="$emit('reset')"
            >
            Reset Order Grid
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      localChecked: false 
    }
  }
}
</script>

<style scoped>
.bold-uppercase {
  font-weight: bold;
  text-transform: uppercase;
}
.warning-text {
  font-size: 1.5rem;
  margin-top: 0px; 
}

.modalCard {
  max-width: 800px;
  min-height: 250px;
}

.main {
  background-color: #1e1e1e; 
}
</style>
